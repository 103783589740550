import React, { useState, useEffect } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";

import ClassStudentComponents, { ClassSudentComponentsPicker } from "./classStudentComponents";
import { Input, Button, InputSelect, TextArea } from "../../../../form";
import ToggleComponent from "../../../../common/toggleComponent";
import { FullScreenLoader } from "../../../../common";

import {
  getRegions,
  getTeachersPortal,
  updateTeachersPortal,
  getSystemAdminUserGroups,
  getProfessionMaps,
  getProfessions
} from "../../../../../service";

import { FaUnlink } from "react-icons/fa";
import { ArrowBackIcon, editIconPencil, PlusIcon, MinusSquare } from "../../../../../asssets/icons";
import { StarIcon } from "@heroicons/react/24/outline";

const registrationFormInput = [
  { label: "First Name", id: "firstName", enabled: true, allowDisable: false },
  { label: "Last Name", id: "lastName", enabled: true, allowDisable: true },
  { label: "Type of establishment/ organisation", id: "type", enabled: false, allowDisable: true },
  { label: "Name of establishment/ organisation", id: "name", enabled: false, allowDisable: true },
  {
    label: "Address of establishment/ organisation",
    id: "address",
    enabled: false,
    allowDisable: true
  },
  { label: "City of establishment/ organisation", id: "city", enabled: false, allowDisable: true },
  {
    label: "Postal Code of establishment/ organisation",
    id: "postalCode",
    enabled: false,
    allowDisable: true
  },
  {
    label: "Region of establishment/ organisation",
    id: "region",
    enabled: false,
    allowDisable: true
  },
  {
    label: "Academy of establishment/ organisation",
    id: "academy",
    enabled: false,
    allowDisable: true
  },
  { label: "School/ Organisation", id: "schoolOrganization", enabled: false, allowDisable: true },
  {
    label: "Function/ Your role in your school/ Organisation",
    id: "functionTask",
    enabled: false,
    allowDisable: true
  },
  { label: "Email", id: "email", enabled: true, allowDisable: true },
  { label: "Region", id: "canton", enabled: true, allowDisable: true },
  { label: "Telephone", id: "phone", enabled: true, allowDisable: true },
  { label: "Language", id: "language", enabled: true, allowDisable: true },
  { label: "Is Teacher?", id: "isTeacher", enabled: true, allowDisable: true },
  { label: "Want Portal Access?", id: "wantAccess", enabled: true, allowDisable: true },
  { label: "Gender", id: "gender", enabled: false, allowDisable: true }
];

const classServicesDefault = {
  participants: { enabled: true, label: "Participants" },
  downloadAccessCodes: { enabled: true, label: "Download Access Codes" },
  downloadUsedAccessCodes: { enabled: true, label: "Download Used Access Codes" },
  vipTestLink: { enabled: true, label: "VIP Test Link" },
  classWebpage: { enabled: true, label: "Class Webpage" },
  downloadVipProfiles: { enabled: true, label: "Download VIP Profiles" },
  downloadStrengthProfiles: { enabled: true, label: "Download Strength Profiles" },
  downloadProfessionalRoleProfiles: { enabled: true, label: "Download Professional Role Profiles" },
  downloadStudentProfiles: { enabled: true, label: "Download Student Profiles" },
  profileConverter: { enabled: true, label: "Profile Converter" },
  transferStudent: { enabled: false, label: "Transfer Student" },
  delete: { enabled: true, label: "Delete Class" }
};

const classDownloadsConfigurationDefault = {
  pdfTemplateNamesAccessCodes: "",
  profilePdfDownloadsConfigVipTitle: "",
  profilePdfDownloadsConfigStrengthTitle: "",
  profilePdfDownloadsConfigStrengthTpl: "",
  profilePdfDownloadsConfigRolesTitle: "",
  profilePdfDownloadsConfigRolesTpl: ""
};

const studentPortalSetupDefault = {
  downloadStudentProfiles: { enabled: false, label: "Download Student PDF at end" }
};

const adminPanelTabsDefault = {
  default: [
    { label: "Welcome (Tab)", id: "welcome", enabled: true, allowDisable: true },
    {
      label: "Introduction & Instructions (Tab)",
      id: "guide",
      enabled: true,
      allowDisable: true,
      buttonLinks: [
        { title: { en: "EN Title", de: "", fr: "", it: "" }, link: "https://google.com" },
        { title: { en: "EN Title", de: "", fr: "", it: "" }, link: "https://google.com" }
      ]
    },
    { label: "Create New Project (Tab)", id: "create-project", enabled: true, allowDisable: true },
    { label: "Manage Projects (Tab)", id: "projects", enabled: true, allowDisable: true },
    { label: "Downloads (Tab)", id: "downloads", enabled: true, allowDisable: true },
    { label: "Contact (Tab)", id: "contact", enabled: true, allowDisable: true },
    { label: "About Us (Tab)", id: "about-us", enabled: true, allowDisable: true },
    {
      label: "Mission Future Class (Tab)",
      id: "mission-future-class",
      enabled: true,
      allowDisable: true
    },
    {
      label: "Mission Future Event (Tab)",
      id: "mission-future-event",
      enabled: true,
      allowDisable: true
    },
    {
      label: "Book a Profile Type",
      id: "book-profile-type",
      enabled: false,
      allowDisable: true,
      subOptions: [
        {
          label: "Date & Time",
          id: "date-time",
          enabled: false,
          allowDisable: true
        },
        {
          label: "Location",
          id: "location",
          enabled: false,
          allowDisable: true
        },
        {
          label: "Profile/Profession Name",
          id: "profile-profession-name",
          enabled: false,
          allowDisable: true
        },
        {
          label: "Comments",
          id: "comments",
          enabled: false,
          allowDisable: true
        },
        {
          label: "Reciever address (who request will be sent to)",
          placeholder: "email@gmail.com",
          id: "reciever-address",
          type: "text-input",
          value: ""
        }
      ]
    }
  ]
};

const classProjectTypesDefault = {
  default: {
    title: { en: "Default Project", de: "", fr: "", it: "" },
    professionMap: "",
    classPage: [
      {
        id: "studentsList",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" }
      },
      {
        id: "strengthWordCloud",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" }
      },
      {
        id: "professionRolesImageCloud",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" }
      },
      {
        id: "profileVideoSelection",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" },
        selectedUsersGroups: [],
        customVideoOptions: []
      },
      {
        id: "professionSelection",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" },
        buttonText: { en: "", de: "", fr: "", it: "" },
        selectedUsersGroups: [],
        professionType: ""
      },
      {
        id: "downloadAllProfessions",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" },
        buttonText: { en: "", de: "", fr: "", it: "" }
      },
      {
        id: "aptitudeWordCloud",
        aptitudeId: uuidv4(),
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" },
        aptitudeCloudOptions: [],
        useOrganizationStrengthsList: false,
        useOrganizationRolesList: false
      },
      {
        id: "regionalLinks",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" },
        selectedRegions: []
      },
      {
        id: "htmlBox",
        html: { en: "", de: "", fr: "", it: "" }
      },
      {
        id: "videosPlayer",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" },
        subText: { en: "", de: "", fr: "", it: "" },
        buttonText: { en: "", de: "", fr: "", it: "" },
        enableAttitudeCloud: false,
        aptitudeId: uuidv4(),
        videos: [{ title: { en: "", de: "", fr: "", it: "" }, url: "" }]
      }
    ],
    studentPage: [
      {
        id: "preferredStrengths",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" }
      },
      {
        id: "preferredProfessionalRoles",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" }
      },
      {
        id: "similarProfileBadges",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" },
        selectedUsersGroups: []
      },
      {
        id: "professionSelection",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" },
        selectedUsersGroups: [],
        professionType: ""
      },
      {
        id: "profileDownload",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" },
        buttonText: { en: "", de: "", fr: "", it: "" }
      },
      {
        id: "htmlBox",
        html: { en: "", de: "", fr: "", it: "" }
      },
      {
        id: "videosPlayer",
        foregroundHeading: { en: "", de: "", fr: "", it: "" },
        backgroundHeading: { en: "", de: "", fr: "", it: "" },
        subText: { en: "", de: "", fr: "", it: "" },
        buttonText: { en: "", de: "", fr: "", it: "" },
        enableAttitudeCloud: false,
        aptitudeId: uuidv4(),
        videos: [{ title: { en: "", de: "", fr: "", it: "" }, url: "" }]
      }
    ]
  }
};

const TeacherAdminSetup = ({ title, org, currentService, setShowComponent }) => {
  const [maintenanceMode, setMaintenanceMode] = useState({
    enabled: false,
    showLogo: false,
    html: ""
  });
  const [vipTestLink, setVipTestLink] = useState("");
  const [serverBaseUrl, setServerBaseUrl] = useState("");
  const [registrationPath, setRegistrationPath] = useState("");
  const [productId, setProductId] = useState("");
  const [registrationForm, setRegistrationForm] = useState([...registrationFormInput]);
  const [classServices, setClassServices] = useState({ ...classServicesDefault });
  const [classDownloadsConfig, setClassDownloadsConfig] = useState({
    ...classDownloadsConfigurationDefault
  });
  const [studentPortalSetup, setStudentPortalSetup] = useState({ ...studentPortalSetupDefault });
  const [adminPanelTabs, setAdminPanelTabs] = useState({ ...adminPanelTabsDefault });
  const [classProjectTypes, setClassProjectTypes] = useState({ ...classProjectTypesDefault });
  const [regions, setRegions] = useState([]);
  const [duplicatedRegions, setDuplicatedRegions] = useState([]);
  const [systemAdminGroups, setSystemAdminGroups] = useState([]);
  const [professionTypes, setProfessionTypes] = useState([]);
  const [professionMaps, setProfessionMaps] = useState([]);
  const [displayToggle, setDisplayToggle] = useState({});
  const [loading, setLoading] = useState(false);

  const toggleMaintenanceMode = () =>
    setMaintenanceMode({ ...maintenanceMode, enabled: !maintenanceMode?.enabled });

  const toggleMaintenanceLogo = () =>
    setMaintenanceMode({ ...maintenanceMode, showLogo: !maintenanceMode?.showLogo });

  const updateMaintenceModeHTML = (updatedHTML) =>
    setMaintenanceMode({ ...maintenanceMode, html: updatedHTML });

  const toggleEnableFormField = (index) => {
    const temp = [...registrationForm];
    temp[index].enabled = !temp[index].enabled;
    setRegistrationForm(temp);
  };

  const toggleRequiredFormField = (index) => {
    const temp = [...registrationForm];
    temp[index].required = !temp[index].required;
    setRegistrationForm(temp);
  };

  const toggleEnableClassService = (service) => {
    const temp = { ...classServices };
    temp[service].enabled = !temp[service].enabled;
    setClassServices(temp);
  };

  const toggleStudentPortalService = (service) => {
    const temp = { ...studentPortalSetup };
    temp[service].enabled = !temp[service].enabled;
    setStudentPortalSetup(temp);
  };

  const toggleAdminPanelTabs = (index, key, subIndex) => {
    const temp = { ...adminPanelTabs };
    if (subIndex !== undefined) {
      temp[key][index].subOptions[subIndex].enabled =
        !temp[key][index].subOptions[subIndex].enabled;
    } else {
      temp[key][index].enabled = !temp[key][index].enabled;
    }
    setAdminPanelTabs(temp);
  };

  const addNewButtonLink = (index, key) => {
    const temp = { ...adminPanelTabs };
    temp[key][index].buttonLinks.push({ title: { en: "", de: "", fr: "", it: "" }, link: "" });
    setAdminPanelTabs(temp);
  };

  const deleteButtonLink = (index, key, buttonIndex) => {
    const temp = { ...adminPanelTabs };
    temp[key][index].buttonLinks.splice(buttonIndex, 1);
    setAdminPanelTabs(temp);
  };

  const updateButtonLinkInfo = (index, key, buttonIndex, newData) => {
    const temp = { ...adminPanelTabs };
    temp[key][index].buttonLinks[buttonIndex] = { ...newData };
    setAdminPanelTabs(temp);
  };

  const updateTextInputValue = (index, key, subIndex, value) => {
    const temp = { ...adminPanelTabs };
    if (subIndex !== undefined) {
      temp[key][index].subOptions[subIndex].value = value;
    } else {
      temp[key][index].value = value;
    }
    setAdminPanelTabs(temp);
  };

  const duplicateForRegion = (duplicateKey = "default", newRegionKey = "", deletePrev = false) => {
    if (duplicatedRegions.length === regions.length) {
      return toast.error("All regions are already duplicated");
    }

    const temp = _.cloneDeep({ ...adminPanelTabs });
    const toCopy = _.cloneDeep([...temp[duplicateKey]]);

    // Fetch next avaialble region
    let nextRegion = regions.find((region) => !duplicatedRegions.includes(region._id));
    if (!nextRegion) return;

    if (newRegionKey) {
      nextRegion = regions.find((region) => region._id === newRegionKey);
    }

    // Set copy
    temp[nextRegion._id] = toCopy;
    setAdminPanelTabs(temp);
    setDuplicatedRegions([...duplicatedRegions, nextRegion._id]);

    // If delete
    if (deletePrev) {
      delete temp[duplicateKey];
      // remove from duplicated regions
      const tempDuplicatedRegions = [...duplicatedRegions];
      const index = tempDuplicatedRegions.indexOf(duplicateKey);
      if (index > -1) {
        tempDuplicatedRegions.splice(index, 1);
        setDuplicatedRegions(tempDuplicatedRegions);
      }

      setAdminPanelTabs(temp);
    }
  };

  const deleteRegion = (key) => {
    const temp = _.cloneDeep({ ...adminPanelTabs });
    const toDelete = temp[key];

    if (toDelete) delete temp[key];

    setAdminPanelTabs(temp);

    const tempDuplicatedRegions = [...duplicatedRegions];
    const index = tempDuplicatedRegions.indexOf(key);
    if (index > -1) {
      tempDuplicatedRegions.splice(index, 1);
      setDuplicatedRegions(tempDuplicatedRegions);
    }
  };

  const updateClassProjectTitle = (variant, { en, de, fr, it }) => {
    const temp = { ...classProjectTypes };
    temp[variant].title = { en, de, fr, it };
    setClassProjectTypes(temp);
  };

  const selectProjectTypeRegionalLink = (type, variant, index, regionId) => {
    const temp = { ...classProjectTypes };
    const selectedRegions = temp[variant]?.[type]?.[index].selectedRegions;
    if (selectedRegions.includes(regionId)) {
      const index = selectedRegions.indexOf(regionId);
      selectedRegions.splice(index, 1);
    } else {
      selectedRegions.push(regionId);
    }

    temp[variant][type][index].selectedRegions = [...selectedRegions];
    setClassProjectTypes(temp);
  };

  const updateBackgroundHeading = (type, variant, index, lang, value) => {
    const temp = { ...classProjectTypes };
    if (!temp[variant][type][index].backgroundHeading) {
      temp[variant][type][index].backgroundHeading = {
        en: "",
        de: "",
        fr: "",
        it: ""
      };
    }
    temp[variant][type][index].backgroundHeading[lang] = value;
    setClassProjectTypes(temp);
  };

  const updateForegroundHeading = (type, variant, index, lang, value) => {
    const temp = { ...classProjectTypes };
    if (!temp[variant][type][index].foregroundHeading) {
      temp[variant][type][index].foregroundHeading = {
        en: "",
        de: "",
        fr: "",
        it: ""
      };
    }
    temp[variant][type][index].foregroundHeading[lang] = value;
    setClassProjectTypes(temp);
  };

  const updateSubText = (type, variant, index, lang, value) => {
    const temp = { ...classProjectTypes };
    if (!temp[variant][type][index].subText) {
      temp[variant][type][index].subText = {
        en: "",
        de: "",
        fr: "",
        it: ""
      };
    }
    temp[variant][type][index].subText[lang] = value;
    setClassProjectTypes(temp);
  };

  const updateButtonText = (type, variant, index, lang, value) => {
    const temp = { ...classProjectTypes };
    if (!temp[variant][type][index].buttonText) {
      temp[variant][type][index].buttonText = {
        en: "",
        de: "",
        fr: "",
        it: ""
      };
    }
    temp[variant][type][index].buttonText[lang] = value;
    setClassProjectTypes(temp);
  };

  const updateHTMLBox = (type, variant, index, lang, value) => {
    const temp = { ...classProjectTypes };
    if (!temp[variant][type][index].html) {
      temp[variant][type][index].html = {
        en: "",
        de: "",
        fr: "",
        it: ""
      };
    }
    temp[variant][type][index].html[lang] = value;
    setClassProjectTypes(temp);
  };

  const updateVideosPlayerTitle = (type, variant, index, videoIndex, lang, value) => {
    const temp = { ...classProjectTypes };
    if (!temp[variant][type][index].videos[videoIndex].title) {
      temp[variant][type][index].videos[videoIndex].title = {
        en: "",
        de: "",
        fr: "",
        it: ""
      };
    }
    temp[variant][type][index].videos[videoIndex].title[lang] = value;
    setClassProjectTypes(temp);
  };

  const updateVideosPlayerUrl = (type, variant, index, videoIndex, value) => {
    const temp = { ...classProjectTypes };
    temp[variant][type][index].videos[videoIndex].url = value;
    setClassProjectTypes(temp);
  };

  const addNewVideoInVideosPlayer = (type, variant, index) => {
    const temp = { ...classProjectTypes };
    temp[variant][type][index].videos.push({ title: { en: "", de: "", fr: "", it: "" }, url: "" });
    setClassProjectTypes(temp);
  };

  const deleteVideoInVideosPlayer = (type, variant, index, videoIndex) => {
    const temp = { ...classProjectTypes };
    temp[variant][type][index].videos.splice(videoIndex, 1);
    setClassProjectTypes(temp);
  };

  const toggleVideoPlayerAptitudeCloud = (type, variant, index) => {
    const temp = { ...classProjectTypes };
    temp[variant][type][index].enableAttitudeCloud =
      !temp[variant][type][index].enableAttitudeCloud;
    setClassProjectTypes(temp);
  };

  const selectUsersGroup = (type, variant, index, groupId) => {
    const temp = { ...classProjectTypes };
    if (!temp[variant]?.[type]?.[index]?.selectedUsersGroups) {
      temp[variant][type][index].selectedUsersGroups = [];
    }
    const selectedUsersGroups = temp[variant]?.[type]?.[index]?.selectedUsersGroups;

    if (selectedUsersGroups.includes(groupId)) {
      const index = selectedUsersGroups.indexOf(groupId);
      selectedUsersGroups.splice(index, 1);
    } else {
      selectedUsersGroups.push(groupId);
    }

    const systemGroupIds = systemAdminGroups
      ?.map((sag) => sag?._id)
      .filter((id) => id !== undefined);

    // Ensuring that only real system group ids get inserted
    temp[variant][type][index].selectedUsersGroups = [...selectedUsersGroups].filter((groupId) =>
      systemGroupIds?.includes(groupId)
    );
    setClassProjectTypes(temp);
  };

  const selectProfessionType = (type, variant, index, professionType) => {
    const temp = { ...classProjectTypes };
    temp[variant][type][index].professionType = professionType;
    setClassProjectTypes(temp);
  };

  const toggleUseOrganizationStrengthsListInAptitudeCloud = (type, variant, index) => {
    const temp = { ...classProjectTypes };

    if (!temp[variant][type][index].useOrganizationStrengthsList) {
      temp[variant][type][index].useOrganizationStrengthsList = false;
    }

    temp[variant][type][index].useOrganizationStrengthsList =
      !temp[variant][type][index].useOrganizationStrengthsList;
    setClassProjectTypes(temp);
  };

  const toggleUseOrganizationRolesListInAptitudeCloud = (type, variant, index) => {
    const temp = { ...classProjectTypes };

    if (!temp[variant][type][index].useOrganizationRolesList) {
      temp[variant][type][index].useOrganizationRolesList = false;
    }

    temp[variant][type][index].useOrganizationRolesList =
      !temp[variant][type][index].useOrganizationRolesList;
    setClassProjectTypes(temp);
  };

  const addAptitudeCloudOption = (type, variant, index) => {
    const temp = { ...classProjectTypes };
    if (!temp[variant][type][index].aptitudeCloudOptions) {
      temp[variant][type][index].aptitudeCloudOptions = [];
    }
    temp[variant][type][index].aptitudeCloudOptions.push({ en: "", de: "", fr: "", it: "" });
    setClassProjectTypes(temp);
  };

  const deleteAptitudeCloudOption = (type, variant, index, optionIndex) => {
    const temp = { ...classProjectTypes };
    temp[variant][type][index].aptitudeCloudOptions.splice(optionIndex, 1);
    setClassProjectTypes(temp);
  };

  const updateAptitudeCloudOption = (type, variant, index, optionIndex, lang, value) => {
    const temp = { ...classProjectTypes };
    temp[variant][type][index].aptitudeCloudOptions[optionIndex][lang] = value;
    setClassProjectTypes(temp);
  };

  // 3 functions for add, delete, update customVideoOptions
  const addCustomVideoOption = (type, variant, index) => {
    const temp = { ...classProjectTypes };
    if (!temp[variant][type][index].customVideoOptions) {
      temp[variant][type][index].customVideoOptions = [];
    }
    temp[variant][type][index].customVideoOptions.push({ en: "", de: "", fr: "", it: "" });
    setClassProjectTypes(temp);
  };

  const deleteCustomVideoOption = (type, variant, index, optionIndex) => {
    const temp = { ...classProjectTypes };
    temp[variant][type][index].customVideoOptions.splice(optionIndex, 1);
    setClassProjectTypes(temp);
  };

  const updateCustomVideoOption = (type, variant, index, optionIndex, lang, value) => {
    const temp = { ...classProjectTypes };
    temp[variant][type][index].customVideoOptions[optionIndex][lang] = value;
    setClassProjectTypes(temp);
  };

  const addClassStudentComponent = (type, variant, componentType) => {
    const temp = { ...classProjectTypes };
    let toAdd = _.cloneDeep({ ...classProjectTypesDefault })?.default?.[type]?.find(
      (element) => element.id === componentType
    );

    if (componentType === "aptitudeWordCloud") toAdd = { ...toAdd, aptitudeId: uuidv4() };

    if (componentType === "professionSelection") toAdd = { ...toAdd, professionSelId: uuidv4() };

    if (componentType === "profileVideoSelection")
      toAdd = { ...toAdd, profileVideoSelId: uuidv4() };

    if (componentType === "videosPlayer") toAdd = { ...toAdd, aptitudeId: uuidv4() };

    temp[variant][type].push(toAdd);
    setClassProjectTypes(temp);
  };

  const deleteClassStudentComponent = (type, variant, index) => {
    const temp = { ...classProjectTypes };
    temp[variant][type].splice(index, 1);
    setClassProjectTypes(temp);
  };

  const duplicateClassProject = (variant) => {
    const temp = { ...classProjectTypes };
    const toCopy = _.cloneDeep({ ...temp[variant] });
    const nextVariant = `variant_${uuidv4()}`;

    temp[nextVariant] = toCopy;
    // update title to mention it is a copy
    temp[nextVariant].title.en += " (Copy)";
    setClassProjectTypes(temp);
  };

  const deleteClassProject = (variant) => {
    const temp = { ...classProjectTypes };

    if (variant !== "default" && temp[variant] && Object.keys(temp).length > 1) {
      delete temp[variant];
    }
    setClassProjectTypes(temp);

    saveTeacherAdminSetup();
  };

  const onDragEndClassStudentSetup = (type = "classPage", variant, result) => {
    if (!result.destination) return;

    try {
      const temp = { ...classProjectTypes };
      const items = [...temp[variant][type]];
      const [removed] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, removed);
      temp[variant][type] = items;
      setClassProjectTypes(temp);
    } catch (error) {
      console.error("Drag and Drop error", error);
    }
  };

  const saveTeacherAdminSetup = async () => {
    try {
      setLoading("Saving");

      const body = {
        maintenanceMode,
        vipTestLink,
        serverBaseUrl,
        registrationPath,
        productId,
        registrationForm,
        classServices,
        classDownloadsConfig,
        studentPortalSetup,
        adminPanelTabs,
        classProjectTypes
      };
      await updateTeachersPortal(currentService._id, currentService.masterOrganization, body);
      toast.success(currentService?.name + " Setup saved successfully");
    } catch (error) {
      console.log(error?.response?.data?.message);
      toast.error("Failed to save Teacher Admin Setup because " + error?.response?.data?.message);
      console.error("Save Teacher Admin Setup error", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch regions and system admin groups & profession maps etc
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getRegions(org?._id);
        setRegions(data);

        const { data: userGroupsData } = await getSystemAdminUserGroups(org?._id);
        const temp = [];
        userGroupsData.forEach((group) => {
          if (!group?.groups) return;

          group.groups.forEach((subGroup) => {
            temp.push(subGroup);
          });
        });
        setSystemAdminGroups(temp);

        const { data: professionMapsData } = await getProfessionMaps(org?._id);
        setProfessionMaps(professionMapsData);

        const { data: professions } = await getProfessions(org?._id);

        try {
          setProfessionTypes(
            Array.from(
              new Set(
                professions
                  .map((p) => {
                    if (p.type !== undefined) return p.type;
                  })
                  .filter((p) => p)
              )
            )
          );
        } catch (error) {
          console.log("Error profession types list", error);
        }
      } catch (error) {
        console.error("Fetch regions error", error);
      }
    })();
  }, []);

  // Fetch Teacher Admin Setup
  useEffect(() => {
    (async () => {
      try {
        setLoading("Loading from DB");
        const { data } = await getTeachersPortal(
          currentService._id,
          currentService.masterOrganization
        );
        if (data.maintenanceMode) setMaintenanceMode(data?.maintenanceMode);
        setVipTestLink(data?.vipTestLink);
        setServerBaseUrl(data?.serverBaseUrl);
        setRegistrationPath(data?.registrationPath);
        setProductId(data?.productId);

        if (data?.registrationForm?.length > 0) {
          const copy = [...registrationForm];
          data.registrationForm.forEach((element, index) => {
            copy[index].enabled = element.enabled;
            copy[index].required = element.required;
          });
          setRegistrationForm([...copy]);
        }

        if (Object?.keys(data?.classServices)?.length > 0) {
          const temp = { ...classServices };
          Object.keys(data?.classServices).forEach((key) => {
            if (temp[key]) {
              temp[key].enabled = data.classServices[key]?.enabled;
            }
          });
          setClassServices(temp);
        }

        if (Object?.keys(data?.classDownloadsConfig)?.length > 0) {
          const temp = { ...classDownloadsConfig };
          Object.keys(data?.classDownloadsConfig).forEach((key) => {
            if (temp.hasOwnProperty(key)) {
              temp[key] = data.classDownloadsConfig[key];
            }
          });
          setClassDownloadsConfig(temp);
        }

        if (data?.studentPortalSetup) {
          if (Object?.keys(data?.studentPortalSetup)?.length > 0) {
            const temp = { ...studentPortalSetup };
            Object.keys(data?.studentPortalSetup).forEach((key) => {
              if (temp.hasOwnProperty(key)) {
                temp[key].enabled = data.studentPortalSetup[key]?.enabled;
              }
            });
            setStudentPortalSetup(temp);
          }
        }

        if (data?.adminPanelTabs?.default?.length > 0) {
          const temp = {};
          Object.keys(data?.adminPanelTabs).forEach((key) => {
            temp[key] = _.cloneDeep([...adminPanelTabsDefault.default]);
            data.adminPanelTabs[key].forEach((element, index) => {
              temp[key][index].enabled = element.enabled;
              if (element.buttonLinks) {
                temp[key][index].buttonLinks = element.buttonLinks;
              }
              if (element.subOptions) {
                element.subOptions.forEach((subOption, subIndex) => {
                  if (subOption?.enabled)
                    temp[key][index].subOptions[subIndex].enabled = subOption?.enabled;
                  if (subOption?.value)
                    temp[key][index].subOptions[subIndex].value = subOption?.value;
                });
              }
            });
          });
          setAdminPanelTabs({ ...temp });
          setDuplicatedRegions(
            Object.keys(data?.adminPanelTabs)?.filter((key) => key !== "default")
          );
        }

        if (data?.classProjectTypes?.default?.classPage?.length > 0) {
          const temp = {};
          Object.keys(data?.classProjectTypes).forEach((variant) => {
            temp[variant] = _.cloneDeep({ ...data?.classProjectTypes[variant] });
          });
          setClassProjectTypes({ ...temp });
        }
      } catch (error) {
        console.error(error, "This setup does not exist " + error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <button
        className="flex gap-3 items-center w-[fit-content] flex-wrap mb-10"
        onClick={setShowComponent}>
        <span className="w-8 h-8">
          <ArrowBackIcon />
        </span>
        <h2 className="text-xl leading-10 font-bold">{title ?? "mission future School"}</h2>
      </button>

      <div className="pl-8">
        {/* Portal Maintenance mode */}
        <div className="my-10 border-[1px] border-[#606060] rounded-md p-4">
          <p
            className="w-full font-bold text-lg mb-2 cursor-pointer select-none"
            onClick={() => {
              setDisplayToggle({
                ...displayToggle,
                maintenanceMode: !displayToggle.maintenanceMode
              });
            }}>
            Teachers Portal Maintenance Mode
          </p>

          <div className={`my-4 ${displayToggle.maintenanceMode ? "block" : "hidden"}`}>
            <div className="flex items-center justify-between mb-4">
              <p className="font-bold">Enable mainteance mode</p>
              <div className="flex gap-3 items-center">
                <ToggleComponent
                  handleCompToggle={() => toggleMaintenanceMode()}
                  isToggle={maintenanceMode?.enabled}
                />
              </div>
            </div>
            {maintenanceMode?.enabled && (
              <div className="flex items-center justify-between mb-4">
                <p className="font-bold">Show logo</p>
                <div className="flex gap-3 items-center">
                  <ToggleComponent
                    handleCompToggle={() => toggleMaintenanceLogo()}
                    isToggle={maintenanceMode?.showLogo}
                  />
                </div>
              </div>
            )}

            {maintenanceMode?.enabled && (
              <div className="my-4">
                <TextArea
                  className={"w-full"}
                  inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                  labelClassName={"text-sm font-normal text-black"}
                  label={"Maintenance Mode HTML"}
                  placeholder={`<div>Site is under construction</div>`}
                  value={maintenanceMode?.html}
                  onChange={(e) => {
                    updateMaintenceModeHTML(e.target.value);
                  }}
                />
                <p className="text-xs text-gray-400 mt-2">
                  This is an approximate preview of this HTML
                </p>
                <div className="mt-2 border border-gray-400 rounded p-2">
                  {maintenanceMode?.showLogo && (
                    <img
                      src={
                        process.env.REACT_APP_AWS_URL +
                        (currentService?.type === "mission-future-school"
                          ? org?.schoolLogo
                          : org?.teamLogo)
                      }
                      className="h-40 w-auto block mx-auto my-4"
                    />
                  )}
                  <div dangerouslySetInnerHTML={{ __html: maintenanceMode?.html }} />
                </div>
              </div>
            )}

            <div className="mt-5 flex justify-end">
              <Button.AdminPanelButton
                type="submit"
                isSave={true}
                loading={false}
                bgColor="#515151"
                textColor="#FFFFFF"
                color="white"
                onClick={saveTeacherAdminSetup}>
                Save
              </Button.AdminPanelButton>
            </div>
          </div>
        </div>

        {/* Class downloads configuration inputs  */}
        <div className="my-10 border-[1px] border-[#606060] rounded-md p-4">
          <p
            className="w-full font-bold text-lg mb-2 cursor-pointer select-none"
            onClick={() => {
              setDisplayToggle({
                ...displayToggle,
                classDownloadsConfig: !displayToggle.classDownloadsConfig
              });
            }}>
            Portal Config Variables
          </p>
          <div className={`${displayToggle.classDownloadsConfig ? "block" : "hidden"}`}>
            <div className="my-4">
              <Input
                inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                label="VIP Test"
                placeholder={"www.viptestlink.co.za"}
                name="vipTestLink"
                type="url"
                defaultValue={vipTestLink}
                onChange={(e) => setVipTestLink(e.target.value)}
              />
            </div>
            <div className="my-4">
              <Input
                inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                label="Server Base URL"
                placeholder={"www.teacherregistration.co.za"}
                name="serverBaseUrl"
                type="url"
                defaultValue={serverBaseUrl}
                onChange={(e) => setServerBaseUrl(e.target.value)}
              />
            </div>
            <div className="my-4">
              <Input
                inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                label="Registration path"
                placeholder={"missionfuture-cc"}
                name="registrationPath"
                type="text"
                defaultValue={registrationPath}
                onChange={(e) => setRegistrationPath(e.target.value)}
              />
            </div>
            <div className="my-4">
              <Input
                inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                label="Product ID"
                placeholder={"42"}
                name="productId"
                type="number"
                min="0"
                defaultValue={productId}
                onChange={(e) => setProductId(e.target.value)}
              />
            </div>
            {Object.keys(classDownloadsConfig).map((key, index) => {
              return (
                <div key={index} className="my-4">
                  <Input
                    inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                    label={key}
                    name={key}
                    type="text"
                    defaultValue={classDownloadsConfig[key]}
                    onChange={(e) =>
                      setClassDownloadsConfig({ ...classDownloadsConfig, [key]: e.target.value })
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>

        {/* Teacher registration page config */}
        <div className="my-10 border-[1px] border-[#606060] rounded-md p-4">
          <p
            className="w-full font-bold text-lg mb-2 cursor-pointer select-none"
            onClick={() => {
              setDisplayToggle({
                ...displayToggle,
                registrationForm: !displayToggle.registrationForm
              });
            }}>
            Teachers Registration Form
          </p>

          <div className={`my-4 ${displayToggle.registrationForm ? "block" : "hidden"}`}>
            {registrationForm.map((element, index) => (
              <div key={index} className="flex items-center justify-between mb-4">
                <p className="font-bold">{element.label}</p>
                <div className="flex gap-3 items-center">
                  {element.allowDisable && (
                    <>
                      <ToggleComponent
                        handleCompToggle={() => toggleEnableFormField(index)}
                        isToggle={element.enabled}
                      />
                      <button
                        className="w-5 h-5 cursor-pointer"
                        onClick={() => toggleRequiredFormField(index)}>
                        <StarIcon fill={element?.required ? "red" : ""} />
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}

            <div className="mt-5 flex justify-end">
              <Button.AdminPanelButton
                type="submit"
                isSave={true}
                loading={false}
                bgColor="#515151"
                textColor="#FFFFFF"
                color="white"
                onClick={saveTeacherAdminSetup}>
                Save
              </Button.AdminPanelButton>
            </div>
          </div>
        </div>

        {/* Class page table features */}
        <div className="my-10 border-[1px] border-[#606060] rounded-md p-4">
          <p
            className="w-full font-bold text-lg mb-2 cursor-pointer select-none"
            onClick={() => {
              setDisplayToggle({
                ...displayToggle,
                classServices: !displayToggle.classServices
              });
            }}>
            Class Page Features
          </p>

          <div className={`my-4 ${displayToggle.classServices ? "block" : "hidden"}`}>
            {Object.keys(classServices).map((key, index) => {
              const service = classServices[key];
              return (
                <div key={index} className="flex items-center justify-between mb-4">
                  <p className="font-bold">{service.label}</p>
                  <div className="flex gap-3 items-center">
                    <button className="w-4 h-4 cursor-pointer">{editIconPencil}</button>
                    <ToggleComponent
                      handleCompToggle={() => toggleEnableClassService(key)}
                      isToggle={service.enabled}
                    />
                  </div>
                </div>
              );
            })}

            <div className="mt-5 flex justify-end">
              <Button.AdminPanelButton
                type="submit"
                isSave={true}
                loading={false}
                bgColor="#515151"
                textColor="#FFFFFF"
                color="white"
                onClick={saveTeacherAdminSetup}>
                Save
              </Button.AdminPanelButton>
            </div>
          </div>
        </div>

        {/* Student portal setup */}
        <div className="my-10 border-[1px] border-[#606060] rounded-md p-4">
          <p
            className="w-full font-bold text-lg mb-2 cursor-pointer select-none"
            onClick={() => {
              setDisplayToggle({
                ...displayToggle,
                studentPortalSetup: !displayToggle.studentPortalSetup
              });
            }}>
            Student Portal Setup
          </p>

          <div className={`my-4 ${displayToggle.studentPortalSetup ? "block" : "hidden"}`}>
            {Object.keys(studentPortalSetup).map((key, index) => {
              const service = studentPortalSetup[key];
              return (
                <div key={index} className="flex items-center justify-between mb-4">
                  <p className="font-bold">{service.label}</p>
                  <div className="flex gap-3 items-center">
                    <button className="w-4 h-4 cursor-pointer">{editIconPencil}</button>
                    <ToggleComponent
                      handleCompToggle={() => toggleStudentPortalService(key)}
                      isToggle={service.enabled}
                    />
                  </div>
                </div>
              );
            })}

            <div className="mt-5 flex justify-end">
              <Button.AdminPanelButton
                type="submit"
                isSave={true}
                loading={false}
                bgColor="#515151"
                textColor="#FFFFFF"
                color="white"
                onClick={saveTeacherAdminSetup}>
                Save
              </Button.AdminPanelButton>
            </div>
          </div>
        </div>

        {/* Teachers Portal Configurations -- Variants */}
        <h3 className="font-bold text-xl mb-2">Teachers Admin Portal</h3>
        {Object.keys(adminPanelTabs).map((key) => {
          const tabDetails = adminPanelTabs[key];
          const tabRegion = regions.find((region) => region._id === key);
          const tabRegionName =
            key === "default"
              ? "Default"
              : tabRegion?.name_en ||
                tabRegion?.name_de ||
                tabRegion?.name_fr ||
                tabRegion?.name_it;

          return (
            <div key={key} className="my-10 border-[1px] border-[#606060] rounded-md p-4">
              <p
                className="w-full font-bold text-lg mb-2 cursor-pointer select-none"
                onClick={() => {
                  setDisplayToggle({
                    ...displayToggle,
                    [`teacherPortal-${key}`]: !displayToggle[`teacherPortal-${key}`]
                  });
                }}>
                ({tabRegionName}) mission future School
              </p>

              <div className={`${displayToggle[`teacherPortal-${key}`] ? "block" : "hidden"}`}>
                {key !== "default" && (
                  <div className="my-4">
                    <label className="block font-bold mb-1" htmlFor={key}>
                      Region-specific
                    </label>
                    <InputSelect
                      name={key}
                      defaultValue={{ value: key, label: tabRegionName }}
                      options={regions
                        .map((region) => {
                          if (region._id === key || duplicatedRegions.includes(region._id))
                            return null;
                          return {
                            value: region._id,
                            label:
                              region.name_en || region.name_de || region.name_fr || region.name_it
                          };
                        })
                        .filter((value) => value !== null)}
                      onChange={(e) => duplicateForRegion(key, e.value, true)}
                    />
                  </div>
                )}
                <>
                  {tabDetails.map((element, index) => {
                    return (
                      <div key={index} className="mb-4">
                        <div className="flex items-center justify-between mb-4">
                          <p className="font-bold text-lg">{element.label}</p>
                          <div className="flex gap-3 items-center">
                            <button className="w-4 h-4 cursor-pointer">{editIconPencil}</button>
                            {element.allowDisable && (
                              <ToggleComponent
                                handleCompToggle={() => toggleAdminPanelTabs(index, key)}
                                isToggle={element.enabled}
                              />
                            )}
                          </div>
                        </div>
                        {element.buttonLinks && (
                          <>
                            <div className="flex flex-row justify-between items-center mb-4">
                              <p className="font-semibold">Button Links</p>
                              <button onClick={() => addNewButtonLink(index, key)}>
                                <PlusIcon />
                              </button>
                            </div>
                            <div className="flex flex-col gap-4">
                              <>
                                {element.buttonLinks.map((button, buttonIndex) => {
                                  return (
                                    <div
                                      key={buttonIndex}
                                      className="relative flex flex-col gap-2 w-full border p-2 pt-5 rounded">
                                      <button
                                        className="absolute right-0 top-0 p-2"
                                        onClick={() => deleteButtonLink(index, key, buttonIndex)}>
                                        <MinusSquare />
                                      </button>
                                      <div className="flex flex-row gap-1">
                                        {["en", "de", "fr", "it"].map((lang) => {
                                          return (
                                            <Input
                                              key={lang}
                                              className={"w-full"}
                                              inputClassName={
                                                "rounded-full border-none bg-[#F6F5F5]"
                                              }
                                              labelClassName={"text-sm font-normal text-[#606060]"}
                                              label={lang.toUpperCase() + " Title"}
                                              placeholder={lang}
                                              value={button.title[lang]}
                                              onChange={(e) => {
                                                const temp = { ...button };
                                                temp.title[lang] = e.target.value;
                                                updateButtonLinkInfo(index, key, buttonIndex, temp);
                                              }}
                                            />
                                          );
                                        })}
                                      </div>
                                      <Input
                                        className={"w-full"}
                                        inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                                        labelClassName={"text-sm font-normal text-[#606060]"}
                                        label="Link"
                                        placeholder={"https://google.com"}
                                        value={button.link}
                                        onChange={(e) => {
                                          const temp = { ...button };
                                          temp.link = e.target.value;
                                          updateButtonLinkInfo(index, key, buttonIndex, temp);
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                              </>
                            </div>
                          </>
                        )}
                        {element.subOptions && (
                          <div className="px-8">
                            {element.subOptions.map((subOption, subIndex) => {
                              if (subOption.type === "text-input")
                                return (
                                  <Input
                                    key={subIndex}
                                    className={"w-full"}
                                    inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                                    labelClassName={"font-bold"}
                                    label={subOption.label}
                                    placeholder={subOption.placeholder ?? ""}
                                    value={subOption.value}
                                    onChange={(e) => {
                                      updateTextInputValue(index, key, subIndex, e.target.value);
                                    }}
                                  />
                                );
                              return (
                                <div
                                  key={subIndex}
                                  className="flex items-center justify-between mb-4">
                                  <p className="font-bold">{subOption.label}</p>
                                  <div className="flex gap-3 items-center">
                                    <button className="w-4 h-4 cursor-pointer">
                                      {editIconPencil}
                                    </button>
                                    {subOption.allowDisable && (
                                      <ToggleComponent
                                        handleCompToggle={() =>
                                          toggleAdminPanelTabs(index, key, subIndex)
                                        }
                                        isToggle={subOption.enabled}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
                <div className="mt-5 flex justify-end gap-3">
                  <Button.AdminPanelButton
                    type="button"
                    isSave={true}
                    loading={false}
                    bgColor="#515151"
                    textColor="#FFFFFF"
                    color="white"
                    onClick={saveTeacherAdminSetup}>
                    Save
                  </Button.AdminPanelButton>
                  {duplicatedRegions.length !== regions.length && (
                    <Button.AdminPanelButton
                      type="button"
                      isSave={true}
                      loading={false}
                      bgColor="#515151"
                      textColor="#FFFFFF"
                      color="white"
                      onClick={() => duplicateForRegion(key)}>
                      Duplicate
                    </Button.AdminPanelButton>
                  )}
                  {/* Delete region */}
                  {key !== "default" && (
                    <Button.AdminPanelButton
                      type="button"
                      isSave={true}
                      loading={false}
                      bgColor="#515151"
                      textColor="#FFFFFF"
                      color="white"
                      onClick={() => deleteRegion(key)}>
                      Delete
                    </Button.AdminPanelButton>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        {/* Class Project Types -- Variants */}
        <p className="font-bold text-xl mb-2">Class Project Type/s</p>
        {Object.keys(classProjectTypes).map((variant, index) => {
          const variantDetails = classProjectTypes[variant];
          const variantTitle =
            variantDetails?.title?.en ||
            variantDetails?.title?.fr ||
            variantDetails?.title?.de ||
            variantDetails?.title?.it;

          return (
            <div key={index} className="my-10 border-[1px] border-[#606060] rounded-md p-4">
              <p
                className="w-full text-lg font-bold mb-2 cursor-pointer select-none"
                onClick={() => {
                  setDisplayToggle({
                    ...displayToggle,
                    [`classProjectType-${variant}`]: !displayToggle?.[`classProjectType-${variant}`]
                  });
                }}>
                {variantTitle}
              </p>
              <div
                className={`${
                  displayToggle?.[`classProjectType-${variant}`] ? "block" : "hidden"
                }`}>
                {/* Variant titles */}
                <div className="flex flex-row gap-1 my-2">
                  {["en", "de", "fr", "it"].map((lang) => {
                    return (
                      <Input
                        key={lang}
                        className={"w-full"}
                        inputClassName={"rounded-full border-none bg-[#F6F5F5]"}
                        labelClassName={"text-sm font-normal text-[#606060]"}
                        label={lang.toUpperCase() + " Title"}
                        placeholder={lang}
                        value={variantDetails?.title?.[lang]}
                        onChange={(e) => {
                          updateClassProjectTitle(variant, {
                            ...variantDetails.title,
                            [lang]: e.target.value
                          });
                        }}
                      />
                    );
                  })}
                </div>
                {/* Select profession map */}
                <ProfessionMapSelection
                  professionMaps={professionMaps}
                  classProjectTypes={classProjectTypes}
                  setClassProjectTypes={setClassProjectTypes}
                  variant={variant}
                  variantDetails={variantDetails}
                  type={"classOverallProfessionMap"}
                />
                <ProfessionMapSelection
                  professionMaps={professionMaps}
                  classProjectTypes={classProjectTypes}
                  setClassProjectTypes={setClassProjectTypes}
                  variant={variant}
                  variantDetails={variantDetails}
                  type={"classGroupedProfessionMap"}
                />
                <ProfessionMapSelection
                  professionMaps={professionMaps}
                  classProjectTypes={classProjectTypes}
                  setClassProjectTypes={setClassProjectTypes}
                  variant={variant}
                  variantDetails={variantDetails}
                  type={"studentProfessionMap"}
                />
                {/* List Class page components */}
                <div className="px-4 py-3 bg-gray-100 rounded-lg">
                  <p
                    className="w-full text-lg font-bold my-2 cursor-pointer select-none"
                    onClick={() => {
                      setDisplayToggle({
                        ...displayToggle,
                        [variant]: {
                          classPage: !displayToggle?.[variant]?.classPage,
                          studentPage: displayToggle?.[variant]?.studentPage
                        }
                      });
                    }}>
                    Class Webpage UI
                  </p>
                  <div className={`${displayToggle?.[variant]?.classPage ? "block" : "hidden"}`}>
                    <DragDropContext
                      onDragEnd={(result) => {
                        onDragEndClassStudentSetup("classPage", variant, result);
                      }}>
                      <Droppable droppableId={`classPage-${variant}-components`}>
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {variantDetails?.classPage?.map(
                              (classPageElement, classPageElementIndex) => (
                                <Draggable
                                  key={`classPage-${variant}-${classPageElement.id}-${classPageElementIndex}`}
                                  draggableId={`classPage-${variant}-${classPageElement.id}-${classPageElementIndex}`}
                                  index={classPageElementIndex}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      id={`classPage-${variant}-${classPageElement.id}-${classPageElementIndex}`}
                                      className="my-5">
                                      <ClassStudentComponents
                                        index={classPageElementIndex}
                                        type={"classPage"}
                                        projectVariant={variant}
                                        projectVariantTitle={variantTitle}
                                        componentType={classPageElement.id}
                                        backgroundHeading={classPageElement.backgroundHeading}
                                        updateBackgroundHeading={updateBackgroundHeading}
                                        foregroundHeading={classPageElement.foregroundHeading}
                                        updateForegroundHeading={updateForegroundHeading}
                                        subText={classPageElement.subText}
                                        updateSubText={updateSubText}
                                        buttonText={classPageElement.buttonText}
                                        updateButtonText={updateButtonText}
                                        html={classPageElement.html}
                                        updateHTMLBox={updateHTMLBox}
                                        videos={classPageElement.videos}
                                        updateVideosPlayerTitle={updateVideosPlayerTitle}
                                        updateVideosPlayerUrl={updateVideosPlayerUrl}
                                        addNewVideoInVideosPlayer={addNewVideoInVideosPlayer}
                                        deleteVideoInVideosPlayer={deleteVideoInVideosPlayer}
                                        videPlayerAptitudeCloudEnabled={
                                          classPageElement?.enableAttitudeCloud
                                        }
                                        toggleVideoPlayerAptitudeCloud={
                                          toggleVideoPlayerAptitudeCloud
                                        }
                                        regions={regions}
                                        selectedRegionalLinks={
                                          classPageElement?.selectedRegions || []
                                        }
                                        selectProjectTypeRegionalLink={
                                          selectProjectTypeRegionalLink
                                        }
                                        availableUsersGroups={systemAdminGroups}
                                        selectedUsersGroups={classPageElement?.selectedUsersGroups}
                                        selectUsersGroup={selectUsersGroup}
                                        professionTypes={professionTypes}
                                        selectedProfessionType={classPageElement?.professionType}
                                        selectProfessionType={selectProfessionType}
                                        useOrganizationStrengthsListInAptitudeCloud={
                                          classPageElement?.useOrganizationStrengthsList
                                        }
                                        toggleUseOrganizationStrengthsListInAptitudeCloud={
                                          toggleUseOrganizationStrengthsListInAptitudeCloud
                                        }
                                        useOrganizationRolesListInAptitudeCloud={
                                          classPageElement?.useOrganizationRolesList
                                        }
                                        toggleUseOrganizationRolesListInAptitudeCloud={
                                          toggleUseOrganizationRolesListInAptitudeCloud
                                        }
                                        aptitudeCloudOptions={
                                          classPageElement?.aptitudeCloudOptions
                                        }
                                        updateAptitudeCloudOption={updateAptitudeCloudOption}
                                        addAptitudeCloudOption={addAptitudeCloudOption}
                                        deleteAptitudeCloudOption={deleteAptitudeCloudOption}
                                        customVideoOptions={classPageElement?.customVideoOptions}
                                        addCustomVideoOption={addCustomVideoOption}
                                        deleteCustomVideoOption={deleteCustomVideoOption}
                                        updateCustomVideoOption={updateCustomVideoOption}
                                        removeComponent={deleteClassStudentComponent}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <ClassSudentComponentsPicker
                      type={"classPage"}
                      projectVariant={variant}
                      addComponent={(type, projectVariant, value) =>
                        addClassStudentComponent(type, projectVariant, value)
                      }
                    />
                  </div>
                </div>
                {/* List Student page components */}
                <div className="px-4 py-3 bg-gray-100 rounded-lg mt-5">
                  <p
                    className="w-full text-lg font-bold my-2 cursor-pointer"
                    onClick={() => {
                      setDisplayToggle({
                        ...displayToggle,
                        [variant]: {
                          classPage: displayToggle?.[variant]?.classPage,
                          studentPage: !displayToggle?.[variant]?.studentPage
                        }
                      });
                    }}>
                    Student Webpage UI
                  </p>
                  <div className={`${displayToggle?.[variant]?.studentPage ? "block" : "hidden"}`}>
                    <DragDropContext
                      onDragEnd={(result) => {
                        onDragEndClassStudentSetup("studentPage", variant, result);
                      }}>
                      <Droppable droppableId={`studentPage-${variant}-components`}>
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {variantDetails?.studentPage?.map(
                              (studentPageElement, studentPageElementIndex) => (
                                <Draggable
                                  key={`studentPage-${variant}-${studentPageElement.id}-${studentPageElementIndex}`}
                                  draggableId={`studentPage-${variant}-${studentPageElement.id}-${studentPageElementIndex}`}
                                  index={studentPageElementIndex}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      id={`studentPage-${variant}-${studentPageElement.id}-${studentPageElementIndex}`}
                                      className="my-5">
                                      <ClassStudentComponents
                                        index={studentPageElementIndex}
                                        type={"studentPage"}
                                        projectVariant={variant}
                                        projectVariantTitle={variantTitle}
                                        componentType={studentPageElement.id}
                                        backgroundHeading={studentPageElement.backgroundHeading}
                                        updateBackgroundHeading={updateBackgroundHeading}
                                        foregroundHeading={studentPageElement.foregroundHeading}
                                        updateForegroundHeading={updateForegroundHeading}
                                        subText={studentPageElement.subText}
                                        updateSubText={updateSubText}
                                        buttonText={studentPageElement.buttonText}
                                        updateButtonText={updateButtonText}
                                        html={studentPageElement.html}
                                        updateHTMLBox={updateHTMLBox}
                                        videos={studentPageElement.videos}
                                        updateVideosPlayerTitle={updateVideosPlayerTitle}
                                        updateVideosPlayerUrl={updateVideosPlayerUrl}
                                        addNewVideoInVideosPlayer={addNewVideoInVideosPlayer}
                                        deleteVideoInVideosPlayer={deleteVideoInVideosPlayer}
                                        videPlayerAptitudeCloudEnabled={
                                          studentPageElement?.enableAttitudeCloud
                                        }
                                        toggleVideoPlayerAptitudeCloud={
                                          toggleVideoPlayerAptitudeCloud
                                        }
                                        regions={regions}
                                        selectedRegionalLinks={
                                          studentPageElement?.selectedRegions || []
                                        }
                                        selectProjectTypeRegionalLink={
                                          selectProjectTypeRegionalLink
                                        }
                                        availableUsersGroups={systemAdminGroups}
                                        selectedUsersGroups={
                                          studentPageElement?.selectedUsersGroups
                                        }
                                        selectUsersGroup={selectUsersGroup}
                                        professionTypes={professionTypes}
                                        selectedProfessionType={studentPageElement?.professionType}
                                        selectProfessionType={selectProfessionType}
                                        removeComponent={deleteClassStudentComponent}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <ClassSudentComponentsPicker
                      type={"studentPage"}
                      projectVariant={variant}
                      addComponent={(type, projectVariant, value) =>
                        addClassStudentComponent(type, projectVariant, value)
                      }
                    />
                  </div>
                </div>
                <div className="mt-5 flex gap-3 justify-end">
                  <Button.AdminPanelButton
                    type="submit"
                    isSave={true}
                    loading={false}
                    bgColor="#515151"
                    textColor="#FFFFFF"
                    color="white"
                    onClick={saveTeacherAdminSetup}>
                    Save
                  </Button.AdminPanelButton>
                  {variant !== "default" && Object.keys(classProjectTypes)?.length > 1 && (
                    <Button.AdminPanelButton
                      type="button"
                      isSave={true}
                      loading={false}
                      bgColor="#515151"
                      textColor="#FFFFFF"
                      color="white"
                      onClick={() => deleteClassProject(variant)}>
                      Delete
                    </Button.AdminPanelButton>
                  )}
                  <Button.AdminPanelButton
                    type="button"
                    isSave={true}
                    loading={false}
                    bgColor="#515151"
                    textColor="#FFFFFF"
                    color="white"
                    onClick={() => duplicateClassProject(variant)}>
                    Duplicate
                  </Button.AdminPanelButton>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {loading && <FullScreenLoader text={loading} />}
    </>
  );
};

const ProfessionMapSelection = ({
  professionMaps,
  classProjectTypes,
  setClassProjectTypes,
  variant,
  variantDetails,
  type
}) => {
  const titles = {
    classOverallProfessionMap: "Class Overall",
    classGroupedProfessionMap: "Class Grouped",
    studentProfessionMap: "Student"
  };
  return (
    <div className="my-4">
      <div className="flex gap-3 items-end">
        <div className="w-full">
          <label className="block font-bold mb-1" htmlFor={variant}>
            {titles[type]} -
            <span className="text-cyan-600">
              {" "}
              {professionMaps?.find((pm) => pm._id === variantDetails?.[type])?.title}
            </span>
          </label>
          <InputSelect
            name={variant}
            className={"!mb-0"}
            value={{
              value: variantDetails?.[type],
              label: professionMaps?.find((pm) => pm._id === variantDetails?.[type])?.title
            }}
            options={professionMaps
              .map((map) => {
                return {
                  value: map._id,
                  label: map.title
                };
              })
              .filter((map) => map.value !== variantDetails?.[type])}
            onChange={(e) => {
              const temp = { ...classProjectTypes };
              if (!temp[variant][type]) temp[variant][type] = "";
              temp[variant][type] = e.value;
              setClassProjectTypes(temp);
            }}
          />
        </div>

        {classProjectTypes[variant]?.[type] && (
          <button
            className="rounded-full text-red-600 p-1 border-red-600 border-2 flex items-center justify-center hover:bg-red-600 hover:text-white"
            onClick={() => {
              const temp = { ...classProjectTypes };
              if (!temp[variant][type]) return;

              temp[variant][type] = "";
              setClassProjectTypes(temp);
            }}>
            <FaUnlink className="w-6 h-6" />
          </button>
        )}
      </div>
    </div>
  );
};

export default TeacherAdminSetup;
